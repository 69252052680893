import React, { useEffect, useState } from "react";
import styles from "./urlinputs.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAndroid, faApple } from "@fortawesome/free-brands-svg-icons";
import {
  faCheck,
  faGlobe,
  faQuestionCircle,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { updateLink } from "../../../slices/linkSlice";
import axios from "axios";

const UrlInputs = ({
  selectedLink,
  name,
  setEdit,
  setName,
  setCustomLink,
  custom_link,
  warning,
}) => {
  const [androidLink, setAndroidLink] = useState(
    selectedLink.android_link || ""
  );
  const [iosLink, setIosLink] = useState(selectedLink.ios_link || "");
  const [webLink, setWebLink] = useState(selectedLink.web_link || "");
  const [backupLink, setBackupLink] = useState(selectedLink.backup_link || "");
  const [showbtns, setShowbtns] = useState(false);
  const [iosWarning, setIosWarning] = useState(false);
  const dispatch = useDispatch();
  const reset = () => {
    setAndroidLink(selectedLink?.android_link || "");
    setIosLink(selectedLink?.ios_link || "");
    setWebLink(selectedLink?.web_link || "");
    setName(selectedLink?.name || "Name");
    setCustomLink(selectedLink?.custom_link);
    setEdit(false);
  };
  const callUpdate = () => {
    if (warning) {
      alert("link is not available or cannot be empty");
    } else {
      const data = {
        link_id: selectedLink?._id,
        custom_link,
        name,
        android_link: androidLink || "",
        ios_link: iosLink || "",
        web_link: webLink || "",
        backup_link: backupLink || "",
      };
      try {
        dispatch(updateLink(data));
      } catch (error) {
        console.log(error);
      }

      setEdit(false);
    }
  };
  useEffect(() => {
    setAndroidLink(selectedLink?.android_link || "");
    setIosLink(selectedLink?.ios_link || "");
    setWebLink(selectedLink?.web_link || "");
    setName(selectedLink?.name || "Name");
    setCustomLink(selectedLink?.custom_link);
    setBackupLink(selectedLink?.backup_link || "");
  }, [selectedLink]);
  useEffect(() => {
    if (
      iosLink !== selectedLink?.ios_link ||
      androidLink !== selectedLink?.androidLink ||
      webLink !== selectedLink?.web_link
    ) {
      if (!showbtns) {
        setShowbtns(true);
      }
    }
  }, [iosLink, androidLink]);
  const handleAndroidChange = (e) => {
    setAndroidLink(e.target.value);
  };
  return (
    <>
      <div className={styles.urlcontainer}>
        <h3>Add your download links</h3>
        <div className={styles.urlgrid}>
          <div className={styles.urlL}>
            <p>URL to your app on AppStore (iOS)</p>
            <div className={styles.urlinput}>
              <FontAwesomeIcon icon={faApple} />
              <input
                type="text"
                value={iosLink}
                onChange={(e) => setIosLink(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.urlR}>
            <p>URL to your app for Android, e.g. Google Play</p>

            <div className={styles.urlinput}>
              <FontAwesomeIcon icon={faAndroid} />
              <input
                type="text"
                value={androidLink}
                onChange={handleAndroidChange}
              />
            </div>
          </div>
          <div className={styles.urlL}>
            <p>URL for any other device/browser</p>
            <div className={styles.urlinput}>
              <FontAwesomeIcon icon={faGlobe} />
              <input
                type="text"
                value={webLink}
                onChange={(e) => setWebLink(e.target.value)}
              />
            </div>
          </div>
          <div className={styles.urlR}>
            <p>URL for Backup</p>
            <div className={styles.urlinput}>
              <FontAwesomeIcon icon={faGlobe} />
              <input
                type="text"
                value={backupLink}
                onChange={(e) => setBackupLink(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div
        className={styles.editbuttons}
        style={{
          display: `${showbtns ? "" : "none"}`,
        }}
      >
        <button onClick={callUpdate}>
          <FontAwesomeIcon className={styles.icon} icon={faCheck} /> Save
        </button>
        <button onClick={reset}>
          <FontAwesomeIcon className={styles.icon} icon={faXmark} /> Cancel
        </button>
      </div>
    </>
  );
};

export default UrlInputs;
