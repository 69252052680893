import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Dashboard from "./pages/dashbord/Dashboard";
import VerifyLogin from "./pages/VerifyLogin";
import { Provider } from "react-redux";
import store from "./store";
import { Toaster } from "react-hot-toast"

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/verifylogin/:id",
    element: <VerifyLogin />,
  },
]);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <div className="container">
        <Toaster
          toastOptions={{
            style: {
              color: "#177b82",
              border: "1px solid rgba(23, 123, 130, 0.5)",
              background: "rgba(23, 123, 130, 0.1)",
            },
          }}
        />
        <RouterProvider router={router} />
      </div>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
