import React from "react";
import styles from "./counts.module.css";

const Counts = ({ analytics }) => {
  const androidCount = analytics
    .map((a) => a.android_count)
    .reduce((a, b) => a + b, 0);
  const webCount = analytics.map((a) => a.web_count).reduce((a, b) => a + b, 0);
  const iosCount = analytics.map((a) => a.ios_count).reduce((a, b) => a + b, 0);
  const total = androidCount + webCount + iosCount;
  return (
    <div className={styles.countscontainer}>
      <div className={styles.counts}>
        <div>
          <p className={styles.countname}>Total Requests</p>
          <p className={styles.count}>{total}</p>
        </div>
        <div>
          <p className={styles.countname}>IOS Requests</p>
          <p className={styles.count}>{iosCount}</p>
        </div>
        <div>
          <p className={styles.countname}>Android Requests</p>
          <p className={styles.count}>{androidCount}</p>
        </div>
        <div>
          <p className={styles.countname}>Browser Requests</p>
          <p className={styles.count}>{webCount}</p>
        </div>
      </div>
    </div>
  );
};

export default Counts;
