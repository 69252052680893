import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-hot-toast";
const baseUrl = `${
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_PORT
    : process.env.REACT_APP_PROD_PORT
}/api/v1/link/`;
const config = {
  headers: {
    authorization: undefined,
  },
};
export const getLinkList = createAsyncThunk(
  "link/getLinkList",
  async (_, ThunkApi) => {
    try {
      const token = ThunkApi.getState().user.user.token;
      const selectedLink = ThunkApi.getState().link;
      if (token) {
        config.headers.authorization = token;
        const { data } = await axios.get(`${baseUrl}get_many_link`, config);
        if (data.data.length) {
          ThunkApi.dispatch(selectLink(data.data[0]));
        }
        return data.data;
      }
    } catch (error) {
      const errmessage = error.response.data.message ?? "something went wrong";
      toast.error(errmessage);
      return ThunkApi.rejectWithValue(errmessage);
    }
  }
);
export const createNewLink = createAsyncThunk(
  "link/createNewLink",
  async (_, ThunkApi) => {
    try {
      const token = ThunkApi.getState().user.user.token;
      if (token) {
        config.headers.authorization = token;
        const { data } = await axios.get(`${baseUrl}create_new_link`, config);
        const currentlist = ThunkApi.getState().link.linkList;
        const updatedlist = [...currentlist, data.data];
        ThunkApi.dispatch(updateList(updatedlist));
        return data.data;
      }
    } catch (error) {
      const errmessage = error.response.data.message ?? "something went wrong";
      toast.error(errmessage);
      return ThunkApi.rejectWithValue(errmessage);
    }
  }
);
export const updateLink = createAsyncThunk(
  "link/updateLink",
  async (body, ThunkApi) => {
    try {
      const token = ThunkApi.getState().user.user.token;
      if (token) {
        config.headers.authorization = token;
        const { data } = await axios.post(`${baseUrl}edit_link`, body, config);
        const currentlist = ThunkApi.getState().link.linkList;
        const updatedList = currentlist.map((l) => l);
        const index = currentlist.findIndex((l) => l._id === data.data._id);
        if (index !== -1) {
          updatedList[index] = data.data;
          console.log(updatedList);
          ThunkApi.dispatch(updateList(updatedList));
        }
        return data.data;
      }
    } catch (error) {
      const errmessage = error.response.data.message ?? "something went wrong";
      toast.error(errmessage);
      return ThunkApi.rejectWithValue(errmessage);
    }
  }
);
export const deleteLink = createAsyncThunk(
  "link/deleteLink",
  async (body, ThunkApi) => {
    try {
      const token = ThunkApi.getState().user.user.token;
      if (token) {
        config.headers.authorization = token;
        const { data } = await axios.post(
          `${baseUrl}delete_link`,
          body,
          config
        );
        if (data) {
          ThunkApi.dispatch(getLinkList());
          toast.success("Link Deleted");
        }
      }
    } catch (error) {
      console.log(error);
      toast.error("unable to delete");
    }
  }
);
const initialState = {
  linkList: [],
  selectedLink: undefined,
  isLoading: false,
  errorMessage: null,
};
const linkSlice = createSlice({
  name: "link",
  initialState,
  reducers: {
    selectLink: (state, action) => {
      state.selectedLink = action.payload;
    },
    updateList: (state, action) => {
      state.linkList = action.payload;
    },
  },
  extraReducers: {
    [getLinkList.pending]: (state) => {
      state.isLoading = true;
    },
    [getLinkList.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload ?? "something went wrong";
      if (!action.payload) {
        toast.error("something went wrong");
      }
    },
    [getLinkList.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.linkList = action.payload;
    },
    [createNewLink.pending]: (state) => {
      state.isLoading = true;
    },
    [createNewLink.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.selectedLink = action.payload;
      toast.success("Link Created");
    },
    [createNewLink.rejected]: (state, action) => {
      if (!action.payload) {
        toast.error("something went wrong");
      }
    },
    [updateLink.pending]: (state) => {
      state.isLoading = true;
    },
    [updateLink.fulfilled]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = null;
      state.selectedLink = action.payload;
      toast.success("Link updated");
    },
    [updateLink.rejected]: (state, action) => {
      state.isLoading = false;
      state.errorMessage = action.payload;
      if (!action.payload) {
        toast.error("something went wrong");
      }
    },
  },
});
export const { selectLink, updateList } = linkSlice.actions;
export default linkSlice.reducer;
