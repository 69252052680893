import React, { useEffect, useState } from "react";

import styles from "./navbar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faCircleUser, faX } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../slices/userSlice";
import { Link, useLocation, useNavigate } from "react-router-dom";
const Navbar = ({ nav, setNav }) => {
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  const userpage = () => {
    dispatch(logout());
    window.location.assign(
      `${
        process.env.NODE_ENV === "development"
          ? process.env.REACT_APP_LOCAL_AUTH_URL
          : process.env.REACT_APP_PROD_AUTH_URL
      }`
    );
  };
  return (
    <div className={styles.navcontainer}>
      <nav className={styles.navbar}>
        <div className={styles.logocontainer}>
          {pathname === "/" ? (
            <></>
          ) : (
            <div className={styles.menu} onClick={() => setNav(!nav)}>
              {nav ? (
                <FontAwesomeIcon icon={faX} />
              ) : (
                <FontAwesomeIcon icon={faBars} />
              )}
            </div>
          )}

          <div className={styles.logo} onClick={() => navigate("/")}>
            OneLink
          </div>
        </div>
        {pathname === "/" ? (
          <button>
            <Link to={"/dashboard"}>My Links</Link>
          </button>
        ) : (
          <button onClick={userpage}>
            <FontAwesomeIcon icon={faCircleUser} className={styles.icon} />{" "}
            {user?.email?.split("@")[0]}
          </button>
        )}
      </nav>
    </div>
  );
};

export default Navbar;
