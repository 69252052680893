import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { toast } from "react-hot-toast";
const baseUrl = `${
  process.env.NODE_ENV === "development"
    ? process.env.REACT_APP_LOCAL_PORT
    : process.env.REACT_APP_PROD_PORT
}/api/v1/auth/`;
export const verifyUser = createAsyncThunk(
  "user/verifyUser",
  async (data, ThunkApi) => {
    try {
      const config = {
        headers: {
          authorization: `Bearer ${data}`,
        },
      };
      const res = await axios.get(`https://onelink.itoi.app/api/v1/auth/verifyuser`, config);
      if (res.data) {
        localStorage.setItem("oneLinkUser", JSON.stringify(res.data));
        return res.data;
      }
    } catch (error) {
      const errmessage = error.response.data.message || "something went wrong";
      toast.error(errmessage);
      return ThunkApi.rejectWithValue(errmessage);
    }
  }
);
const localUser = JSON.parse(localStorage.getItem("oneLinkUser"));
const initialState = {
  user: localUser || null,
  userLoading: false,
  userError: false,
  errorMessage: null,
};
const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logout: (state) => {
      localStorage.removeItem("oneLinkUser");
      state.user = null;
    },
  },
  extraReducers: {
    [verifyUser.pending]: (state) => {
      state.userLoading = true;
      state.userError = false;
    },
    [verifyUser.rejected]: (state, action) => {
      state.errorMessage = action.payload;
      state.userError = true;
      state.userLoading = false;
    },
    [verifyUser.fulfilled]: (state, action) => {
      state.userError = false;
      state.userLoading = false;
      state.user = action.payload;
    },
  },
});
export const { logout } = userSlice.actions;
export default userSlice.reducer;
