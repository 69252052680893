import React, { useEffect, useState } from "react";
import styles from "./statistics.module.css";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Statistics = ({ link }) => {
  const { selectedLink } = useSelector((state) => state.link);
  const analytics = selectedLink?.analytics;
  const currentDate = moment().format("YYYY-MM-DD");
  const thirtyDayAgo = moment(currentDate).subtract(30, "days");
  const dateRange = [];
  let currentDateInRange = thirtyDayAgo.clone();
  while (currentDateInRange.isSameOrBefore(currentDate)) {
    dateRange.push(currentDateInRange.format("YYYY-MM-DD"));
    currentDateInRange.add(1, "day");
  }
  const modifiedAnalytics = dateRange.map((date) => {
    const data = analytics.find((item) => item.date.split("T")[0] === date);
    return {
      date,
      android_count: data ? data.android_count : 0,
      web_count: data ? data.web_count : 0,
      ios_count: data ? data.ios_count : 0,
    };
  });
  const [chartData, setChartData] = useState(modifiedAnalytics);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [chartTitle, setChartTitle] = useState("Last 30 Days");
  useEffect(() => {
    if (startDate && endDate) {
      let fromDate = moment(startDate);
      const toDate = moment(endDate);
      if (toDate.isBefore(fromDate)) {
        return alert("end date cannot be before start date");
      }
      const range = [];
      while (fromDate.isSameOrBefore(toDate)) {
        range.push(fromDate.format("YYYY-MM-DD"));
        fromDate.add(1, "day");
      }
      const modifiedAnalytics = range.map((date) => {
        const data = analytics.find((item) => item.date.split("T")[0] === date);
        return {
          date,
          android_count: data ? data.android_count : 0,
          web_count: data ? data.web_count : 0,
          ios_count: data ? data.ios_count : 0,
        };
      });
      setChartTitle(`${startDate} to ${endDate}`);
      setChartData(modifiedAnalytics);
    } else {
      setChartTitle("Last 30 Days");
      setChartData(modifiedAnalytics);
    }
  }, [selectedLink, startDate, endDate]);
  const options = {
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: chartTitle,
      },
    },
  };
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const labels = chartData.map((a) => {
    const date = new Date(a.date);
    const formatedDate = `${date.getDate()}-${
      date.getMonth() + 1
    }-${date.getFullYear()}`;
    return formatedDate;
  });
  const data = {
    labels,
    datasets: [
      {
        label: "ios",
        data: chartData.map((a) => a.ios_count),
        borderColor: "black",
        backgroundColor: "black",
      },
      {
        label: "android",
        data: chartData.map((a) => a.android_count),
        borderColor: "green",
        backgroundColor: "green",
      },
      {
        label: "web",
        data: chartData.map((a) => a.web_count),
        borderColor: "blue",
        backgroundColor: "blue",
      },
    ],
  };

  return (
    <div className={styles.statcontainer}>
      <p className={styles.title}>Usage Statistics</p>
      <div className={styles.graphOptions}>
        <div>
          <label htmlFor="startDate">Start Date</label>
          <div className={styles.datePicker}>
            <p>Date</p>
            <input
              className={styles.dateInput}
              id="startDate"
              min={analytics[0].date.split("T")[0]}
              max={currentDate}
              type="date"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
        </div>
        <div>
          <label>End Date</label>
          <div className={styles.datePicker}>
            <p>Date</p>

            <input
              className={styles.dateInput}
              type="date"
              max={currentDate}
              disabled={!startDate}
              min={startDate}
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
        </div>
        <button
          className={styles.btnBox}
          onClick={() => {
            setStartDate("");
            setEndDate("");
          }}
        >
          Last 30 days
        </button>
      </div>
      <Line options={options} data={data} />
    </div>
  );
};

export default Statistics;
