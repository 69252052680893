import React, { useEffect } from "react";
import styles from "./sideBar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { createNewLink, getLinkList, selectLink } from "../../slices/linkSlice";
const SideBar = ({ nav, setNav }) => {
  const { user } = useSelector((state) => state.user);
  const { linkList, selectedLink } = useSelector((state) => state.link);
  const dispatch = useDispatch();
  return (
    <div className={nav ? styles.activeSidebar : styles.inactiveSidebar}>
      <button
        className={styles.cnbtn}
        onClick={() => {
          dispatch(createNewLink());
        }}
      >
        <FontAwesomeIcon icon={faPlus} /> Create Link
      </button>
      {linkList?.length ? (
        <div className={styles.linkListcontainer}>
          {linkList.map((link, i) => {
            const style =
              link._id === selectedLink._id ? styles.selectedLink : styles.link;
            return (
              <div
                className={style}
                key={i}
                onClick={() => {
                  dispatch(selectLink(link));
                  setNav(false);
                }}
              >
                <p className={styles.linkname}>{link?.name}</p>
                <p>{process.env.REACT_APP_PROD_PORT}/{link.custom_link}</p>
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SideBar;
