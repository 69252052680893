import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar/Navbar";
import styles from "./dashboard.module.css";

import SideBar from "../../components/sideBar/SideBar";
import Main from "../../components/main/Main";
import { useDispatch, useSelector } from "react-redux";
import { getLinkList } from "../../slices/linkSlice";
import Statistics from "../../components/statistics/Statistics";
import { Rings } from "react-loader-spinner";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { verifyUser } from "../../slices/userSlice";

const Dashboard = () => {
  const [query] = useSearchParams();
  const token = query.get("token");
  const location = useLocation();
  const navigate = useNavigate();
  const { user, userLoading, userError, errorMessage } = useSelector(
    (state) => state.user
  );
  const { linkList, isLoading, selectedLink } = useSelector(
    (state) => state.link
  );
  const dispatch = useDispatch();
  const [nav, setNav] = useState(false);
  useEffect(() => {
    if (user && !token) {
      dispatch(getLinkList());
    } else navigate(location.pathname);
  }, [user]);
  useEffect(() => {
    if (!user && !token) {
      window.location.assign(
        `${
          process.env.NODE_ENV === "development"
            ? process.env.REACT_APP_LOCAL_AUTH_URL
            : process.env.REACT_APP_PROD_AUTH_URL
        }/login?redirecturl=${window.location.href}`
      );
    } else if (!user && token) {
      dispatch(verifyUser(token));
    }
  }, []);
  if (!user && userLoading) {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100dvh",
        }}
      >
        <Rings
          height="200"
          width="200"
          color="#177b82"
          radius="10"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
          ariaLabel="rings-loading"
        />
      </div>
    );
  }
  if (!user && userError) {
    return (
      <div className={styles.emptylist}>
        <p>Login Failed</p>
        <p>{errorMessage}</p>
        <button
          className={styles.cnbtn}
          onClick={() => {
            window.location.assign(
              `${
                process.env.NODE_ENV === "development"
                  ? process.env.REACT_APP_LOCAL_AUTH_URL
                  : process.env.REACT_APP_PROD_AUTH_URL
              }/login?redirecturl=${window.location.href}`
            );
          }}
        >
          Try again
        </button>
      </div>
    );
  }
  if (user)
    return (
      <div className={styles.dashboard}>
        <Navbar
          username={user?.email?.split("@")[0]}
          nav={nav}
          setNav={setNav}
        />
        {isLoading ? (
          <div className={styles.loader}>
            <Rings
              height="200"
              width="200"
              color="#177b82"
              radius="10"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="rings-loading"
            />
          </div>
        ) : (
          <>
            <div className={styles.gridcontainer}>
              <SideBar nav={nav} setNav={setNav} />
              <Main />
            </div>
            {selectedLink ? <Statistics link={selectedLink} /> : <></>}
          </>
        )}
      </div>
    );
};

export default Dashboard;
