import { Link } from "react-router-dom";
import "./App.css";
import Navbar from "./components/navbar/Navbar";

function App() {
  return (
    <div className="App">
      <Navbar />
      <main>
        <p className="head">
          Simplify links with <span>One Link</span> to all app stores
        </p>
        <div className="about">
          <p>
            With <span>onelink</span> you get one short link or QR code to your
            app.
          </p>
          <p>
            Instead of printing several QR codes or having several separate
            links to each app store, you only need one short link or QR code.
            Works on iOS, Android, Windows and Mac.
          </p>
        </div>
      </main>
    </div>
  );
}

export default App;
