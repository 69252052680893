import React, { useEffect, useRef, useState } from "react";
import styles from "./main.module.css";
import { faCopy, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faDownload, faPen, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QRCode from "qrcode.react";
import { useDispatch, useSelector } from "react-redux";
import UrlInputs from "./urlInputs/UrlInputs";
import { createNewLink, deleteLink } from "../../slices/linkSlice";
import Counts from "./counts/Counts";
import axios from "axios";
const Main = () => {
  const { linkList, selectedLink } = useSelector((state) => state.link);
  const [edit, setEdit] = useState(false);
  const [customLink, setCustomLink] = useState(selectedLink?.custom_link || "");
  const [warning, setWarning] = useState(false);
  const [name, setName] = useState(selectedLink?.name || "Name");
  const dispatch = useDispatch();
  const urlref = useRef(null);
  useEffect(() => {
    if (customLink === selectedLink?.custom_link) {
      setWarning(false);
    }
    if (customLink === "") {
      setWarning(true);
    }
    let timer;
    const debounceDelay = 500;
    const verifycustomLink = async () => {
      try {
        const { data } = await axios.get(
          `${
            process.env.NODE_ENV === "development"
              ? process.env.REACT_APP_LOCAL_PORT
              : process.env.REACT_APP_PROD_PORT
          }/api/v1/link/verify_link/${customLink}`
        );
        if (data && customLink !== selectedLink.custom_link) {
          setWarning(true);
        }
      } catch (error) {
        setWarning(false);
      }
    };
    const debounce = () => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        verifycustomLink();
      }, debounceDelay);
    };
    if (edit && customLink) {
      debounce();
    }

    return () => {
      clearTimeout(timer);
    };
  }, [customLink, edit]);
  useEffect(() => {
    setName(selectedLink?.name || "Name");
    setCustomLink(selectedLink?.custom_link);
  }, [selectedLink]);
  const callDelete = (id) => {
    const body = {
      link_id: id,
    };
    dispatch(deleteLink(body));
  };
  const handleEdit = () => {
    setEdit(true);
  };
  const cancelEdit = () => {
    setName(selectedLink?.name || "Name");
    setCustomLink(selectedLink?.custom_link);
    setEdit(false);
  };
  const copyUrl = () => {
    const text = urlref.current.innerText;
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert("Copied to clipboard");
      })
      .catch((error) => {
        console.error("Error copying to clipboard:", error);
      });
  };
  const handleDownload = () => {
    const canvas = document.getElementById("qr");
    const url = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.href = url;
    link.download = `${selectedLink?.custom_link}.png`;
    link.click();
  };

  if (!linkList?.length) {
    return (
      <div className={styles.emptylist}>
        <p>No links found, create new</p>
        <button
          className={styles.cnbtn}
          onClick={() => {
            dispatch(createNewLink());
          }}
        >
          Create New
        </button>
      </div>
    );
  }
  return (
    <div className={styles.main}>
      <div className={styles.headcontainer}>
        <div className={styles.head}>
          <div className={styles.qrcode}>
            <QRCode
              value={`${process.env.REACT_APP_PROD_PORT}/${selectedLink?.custom_link}`}
              size={120}
              id="qr"
            />
          </div>
          <div className={styles.headcontent}>
            {edit ? (
              <input
                type="text"
                className={styles.nameInput}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            ) : (
              <p className={styles.name}>{name}</p>
            )}

            {edit ? (
              <p className={styles.url} ref={urlref}>
                {process.env.REACT_APP_PROD_PORT}/
                <input
                  className={styles.linkInput}
                  type="text"
                  value={customLink}
                  onChange={(e) => setCustomLink(e.target.value)}
                />
                <span className={warning ? styles.red : styles.green}>
                  {warning ? "Not available" : "Available"}
                </span>
              </p>
            ) : (
              <a
                href={`${process.env.REACT_APP_PROD_PORT}/${selectedLink?.custom_link}`}
                className={styles.url}
                ref={urlref}
              >
                {process.env.REACT_APP_PROD_PORT}/{selectedLink?.custom_link}
              </a>
            )}

            <div className={styles.buttons}>
              <button onClick={copyUrl}>
                <FontAwesomeIcon className={styles.icon} icon={faCopy} /> Copy
              </button>
              {edit ? (
                <button onClick={cancelEdit}>
                  {" "}
                  <FontAwesomeIcon
                    className={styles.icon}
                    icon={faXmark}
                  />{" "}
                  Cancel
                </button>
              ) : (
                <button onClick={handleEdit}>
                  <FontAwesomeIcon className={styles.icon} icon={faPen} /> Edit{" "}
                </button>
              )}

              <button onClick={() => callDelete(selectedLink._id)}>
                <FontAwesomeIcon className={styles.icon} icon={faTrashCan} />{" "}
                Delete
              </button>
              <button onClick={handleDownload}>
                <FontAwesomeIcon className={styles.icon} icon={faDownload} />{" "}
                Download
              </button>
            </div>
          </div>
        </div>
      </div>
      <Counts analytics={selectedLink?.analytics} />
      <UrlInputs
        selectedLink={selectedLink}
        custom_link={customLink}
        name={name}
        setName={setName}
        setCustomLink={setCustomLink}
        setEdit={setEdit}
        warning={warning}
      />
    </div>
  );
};

export default Main;
